/**
 * 请求路径
 */
// export const requestPath = "http://127.0.0.1:8081/";

export const requestPath = "https://scope.shanhe.link/";
// export const requestPath = "http://zwza2f.natappfree.cc/";
// export const requestPath = "http://insfp7.natappfree.cc/";
// export const requestPath = "http://any.nat300.top/";

// export const requestPath = "http://127.0.0.1:8889/";

/**
 * 用来格式化返回数据
 * @param {*} data 响应数据
 * @returns 返回是新的对象
 */
export const formattingResult = (data = []) => {
  if (data.length == 0) return [];
  if (!Array.isArray(data)) {
    if (Object.prototype.toString.call(data) === "[object Object]") {
      data = [data];
    } else {
      return data;
    }
  }

  return {
    data: data.map((item) => {
      return {
        lng: Number.parseFloat(item.longitude),
        lat: Number.parseFloat(item.latitude),
        count: Number.parseFloat(item.registeredCapital),
      };
    }),
    max: 800000,
  };
};
/**
 * 本区域
 * @param {*} d
 * @param {*} arr
 * @returns
 */
export const filteringData = (d = [], arr = []) => {
  // 去除 arr 中的 undefined 元素
  arr.filter((item) => item !== undefined);
  let data = JSON.parse(JSON.stringify(d));

  if (arr.length === 1) {
    return data.filter((province) => province.provinceName !== arr[0]);
  } else if (arr.length === 2) {
    return data
      .map((province) => {
        if (province.provinceName === arr[0]) {
          province.children = province.children.filter(
            (city) => city.cityName !== arr[1]
          );
        }
        return province;
      })
      .filter((province) => province.children.length > 0);
  } else if (arr.length === 3) {
    return data
      .map((province) => {
        if (province.provinceName === arr[0]) {
          province.children = province.children
            .map((city) => {
              if (city.cityName === arr[1]) {
                city.children = city.children.filter(
                  (area) => area.areaName !== arr[2]
                );
              }
              return city;
            })
            .filter((city) => city.children.length > 0);
        }
        return province;
      })
      .filter((province) => province.children.length > 0);
  }

  return data;
};

/**
 * 招商区域
 * @param {*} d
 * @param {*} arr
 * @returns
 */
export function filterData(d = [], arr = []) {
  // 去除 arr 中的 undefined 元素
  arr.filter((subArr) => subArr && subArr.length > 0);

  let data = JSON.parse(JSON.stringify(d.filter((item) => item.cityId != -1)));

  arr.forEach((subArr) => {
    if (subArr.length === 1) {
      data = data.filter((province) => province.provinceId !== subArr[0]);
    } else if (subArr.length === 2) {
      data = data
        .map((province) => {
          if (province.provinceId === subArr[0]) {
            province.children = province.children.filter(
              (city) => city.cityId !== subArr[1]
            );
          }
          return province;
        })
        .filter((province) => province.children.length > 0);
    } else if (subArr.length === 3) {
      data = data
        .map((province) => {
          if (province.provinceId === subArr[0]) {
            province.children = province.children
              .map((city) => {
                if (city.cityId === subArr[1]) {
                  city.children = city.children.filter(
                    (area) => area.areaId !== subArr[2]
                  );
                }
                return city;
              })
              .filter((city) => city.children.length > 0);
          }
          return province;
        })
        .filter((province) => province.children.length > 0);
    }
  });

  return data;
}

/**
 * 转成对象
 * @param {*} array
 * @returns
 */
export const transformArrayToObj = (array) => {
  const result = { P: [], C: [], A: [] };

  array.forEach((subArr) => {
    if (subArr.length > 0) result.P.push(subArr[0]);
    if (subArr.length > 1) result.C.push(subArr[1]);
    if (subArr.length > 2) result.A.push(subArr[2]);
  });

  return result;
};

/**
 * 分类
 * @param {*} data
 * @returns
 */
export function classifyData(data) {
  const result = {};

  // 遍历原始数据并分类
  data.forEach(([key, value]) => {
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(value);
  });

  // 将分类结果转换为所需的格式
  const keys = Object.keys(result).map(Number);
  const values = Object.values(result);

  return {
    city: keys,
    area: values,
  };
}

/**
 * 绘制文本
 * @param {*} longitude
 * @param {*} latitude
 * @param {*} text 绘制文本
 * @returns 返回map对象
 */
export const mapDrawText = (longitude, latitude, text) => {
  const point = new BMap.Point(longitude, latitude);
  const marker = new BMap.Marker(point);
  const label = new BMap.Label(text, {
    offset: new BMap.Size(20, -10),
  });
  marker.setLabel(label);
  return marker;
};

/**
 * 是否绘制矩形
 * @param {*} startLng 开始坐标lng
 * @param {*} startLat 开始坐标lat
 * @param {*} endLng  结束坐标lng
 * @param {*} endLat 结束坐标lat
 * @returns true/false
 */
export const mapIsDrawRectangle = (startLng, startLat, endLng, endLat) => {
  const isTo = isEmpty(startLng, startLat, endLng, endLat);

  if (!isTo) {
    return false;
  }

  const widthLongitude = Math.abs(startLng - endLng); // 矩形宽度
  const heightLatitude = Math.abs(startLat - endLat); // 矩形高度

  // 地球平均半径（公里）
  const earthRadius = 6371;

  // 将经纬度差转换为弧度
  const widthLongitudeRad = widthLongitude * (Math.PI / 180);
  const heightLatitudeRad = heightLatitude * (Math.PI / 180);

  // 计算经度差对应的实际距离
  // 注意：经度每一度的距离随纬度变化，此处简化处理
  const widthMeters =
    widthLongitudeRad *
    earthRadius *
    Math.cos((startLat + endLat) * (Math.PI / 360));

  // 计算纬度差对应的实际距离
  const heightMeters = heightLatitudeRad * earthRadius;

  // 如果矩形宽度或高度小于等于.5公里，则不绘制
  if (widthMeters >= 0.5 && heightMeters >= 0.5) {
    return true;
  }
  return false;
};

/**
 * 绘制矩形
 * @param {*} startLng 开始坐标lng
 * @param {*} startLat 开始坐标lat
 * @param {*} endLng  结束坐标lng
 * @param {*} endLat 结束坐标lat
 * @param {*} map map对象
 * @param {*} oldRectangle 旧矩形对象
 * @returns map对象
 */
export const mapDrawRectangle = (
  startLng,
  startLat,
  endLng,
  endLat,
  map,
  oldRectangle
) => {
  // 判断是否已经存在矩形，存在则移除
  if (oldRectangle) {
    map.removeOverlay(oldRectangle);
  }
  let rectanglePoints = [
    new BMap.Point(startLng, startLat), // 左上角
    new BMap.Point(endLng, startLat), // 右上角
    new BMap.Point(endLng, endLat), // 右下角
    new BMap.Point(startLng, endLat), // 左下角
    new BMap.Point(startLng, startLat), // 回到左上角以闭合多边形
  ];
  // 设置矩形图样式
  var rectangle = new BMap.Polygon(rectanglePoints, {
    strokeColor: "#FF0000", // 边框颜色
    strokeWeight: 2, // 边框宽度
    strokeOpacity: 0.5, // 边框透明度
    fillColor: "#FF0000", // 填充颜色
    fillOpacity: 0.1, // 填充透明度
  });

  map.addOverlay(rectangle);
  return rectangle;
};

/**
 * 将所有传入的字符串参数以逗号分隔的形式拼接成一个新的字符串。
 * @param  {...any} strings  一个或多个字符串
 * @returns 拼接后的字符串
 */
export const concatenateWithComma = (...strings) => {
  return strings.join(",");
};

/**
 *
 * @param {Array} dataPoints 数据
 * @param {*} startLat 开始坐标lat
 * @param {*} startLng 开始坐标lng
 * @param {*} endLat 结束坐标lat
 * @param {*} endLng 结束坐标lng
 * @returns 过滤后的数据点数组
 */
export const filterDataInRectangle = (
  dataPoints = [],
  startLat,
  startLng,
  endLat,
  endLng
) => {
  return dataPoints.filter((item) => {
    return (
      item.longitude >= Math.min(startLng, endLng) &&
      item.longitude <= Math.max(startLng, endLng) &&
      item.latitude >= Math.min(startLat, endLat) &&
      item.latitude <= Math.max(startLat, endLat)
    );
  });
};

/**
 * 判断对象是否为空
 * @param  {...any} objects
 * @returns 为空返回false，不为空返回true
 */
export const isEmpty = (...objects) => {
  return !objects.some(
    (obj) =>
      obj == null ||
      obj === "" ||
      (typeof obj === "object" && obj !== null && Object.keys(obj).length === 0)
  );
};

/**
 * 查找数据对应下标
 * @param {*} array
 * @param {*} property
 * @param {*} value
 * @returns
 */
export const findObjectIndex = (array, property, value) => {
  return array.findIndex((obj) => obj[property] === value);
};

/**
 * 查找对象对应数据
 * @param {*} array 数据列表
 * @param {*} property 属性名
 * @param {*} value 查找值
 * @returns 对象
 */
export const findObjectByProperty = (array, property, value) => {
  return array.find((obj) => obj[property] === value);
};

/**
 * 查找对象对应所有数据
 * @param {*} array 数据列表
 * @param {*} property 属性名
 * @param {*} value 查找值
 * @returns 对象列表
 */
export const findIndexByPropertyAll = (array, property, value) => {
  return array.filter((obj) => obj[property] === value);
};

/**
 * 回到最底部
 * @param {*} container
 */
export const scrollToBottom = (container) => {
  if (container) {
    container.scrollTop = container.scrollHeight;
  }
};

/**
 * 回到最顶部
 * @param {*} container
 */
export const scrollToTop = (container) => {
  if (container) {
    container.scrollTop = 0;
  }
};

/**
 * 设置websocketId
 * @param {*} data 数据
 */
export const setWebSocketId = (data) => {
  window.localStorage.setItem("websocketId", toJSONString(data));
};

/**
 * 获取websocketId
 * @returns websocketId
 */
export const getWebSocketId = () => {
  const websocketId = window.localStorage.getItem("websocketId");
  return websocketId ? toJSONPares(websocketId) : null;
};
/**
 * 设置uuid
 * @param {*} data
 */
export const setUUId = (data) => {
  window.localStorage.setItem("uuid", toJSONString(data));
};
/**
 * 获取UUId
 * @returns
 */
export const getUUId = () => {
  const uuid = window.localStorage.getItem("uuid");
  return uuid ? toJSONPares(uuid) : null;
};
/**
 * 设置sseId
 * @param {*} data
 */
export const setSSEId = (data) => {
  window.localStorage.setItem("sseId", toJSONString("SSE-" + data));
};

/**
 * 获取sseId
 * @returns
 */
export const getSSEId = () => {
  const sseId = window.localStorage.getItem("sseId");
  return sseId ? toJSONPares(sseId) : null;
};

/**
 * 获取云币剩余信息
 * @param {*} data
 */
export const getCoinInfo = () => {
  const updateCoin = window.localStorage.getItem("updateCoin");
  return updateCoin ? toJSONPares(updateCoin) : null;
};

/**
 * 设置云币剩余信息
 * @param {*} data
 */
export const setCoinInfo = (data) => {
  window.localStorage.setItem("updateCoin", toJSONString(data));
};

/**
 * 设置支付之后的事情
 * @param {*} data
 */
export const setOrderInfo = (data) => {
  window.localStorage.setItem("orderInfo", JSON.stringify(data));
};
/**
 * 获取支付之后的事情
 * @param {*} data
 */
export const getOrderInfo = () => {
  window.localStorage.getItem("orderInfo");
};
/**
 * 退出登陆
 */
export const removeCoinInfo = () => {
  window.localStorage.removeItem("updateCoin");
};

/**
 * 设置登录信息
 * @param {*} data
 */
export const setLoginInfo = (data) => {
  window.localStorage.setItem("loginInfo", toJSONString(data));
};

/**
 * 获取登录信息
 * @returns
 */
export const getLoginInfo = () => {
  const loginInfo = window.localStorage.getItem("loginInfo");
  return loginInfo ? toJSONPares(loginInfo) : null;
};

/**
 * 退出登陆
 */
export const removeLoginInfo = () => {
  removeCoinInfo();
  window.localStorage.removeItem("loginInfo");
};

/**
 * 转json
 * @param {*} data
 * @returns
 */
export const toJSONPares = (data) => {
  return JSON.parse(data);
};

/**
 * 转string
 * @param {*} data
 * @returns
 */
export const toJSONString = (data) => {
  return JSON.stringify(data);
};

/**
 * 高亮提示
 * @param {*} content
 * @param {*} searchText
 * @returns
 */
export const highlightedContent = (content, searchTerms = []) => {
  if (!content) return content;
  const regex = new RegExp(
    searchTerms
      .map((term) => term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"))
      .join("|"),
    "gi"
  ); // 创建正则表达式，忽略大小写
  return content.replace(
    regex,
    (match) =>
      `<span style="color:red; font-size: 14px; font-weight: bold;">“${match}”</span>`
  );
};

/**
 * 判断对象属性为空或者数组长度为零
 * @param {*} obj
 * @returns
 */
export const isAnyNonEmpty = (obj, pass = []) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (pass.includes(key)) {
        continue;
      }
      let value = obj[key];
      if (value !== null && !(Array.isArray(value) && value.length === 0)) {
        return true;
      }
    }
  }
  return false;
};

/**
 * 跳转爱企查页面
 * @param {*} name
 */
export const chaDetailAiQiChan = (name) => {
  window.open(`https://aiqicha.baidu.com/s?q=${name}`);
};

/**
 * 展示对应是文件图片
 * @param fileName 文件名
 */
export const getIconByFileTypeImg = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  switch (extension) {
    case "pdf":
      return require("@/assets/pdf.png"); // 确保路径正确
    case "ppt":
    case "pptx":
      return require("@/assets/PPT.png");
    case "doc":
    case "docx":
      return require("@/assets/WORD.png");
    case "xls": // 添加对旧版Excel文件的支持
    case "xlsx": // 添加对新版Excel文件的支持
      return require("@/assets/excel.png"); // 使用Excel图标
    default:
      return require("@/assets/def.png");
  }
};
