import axios from "axios";
import {
  requestPath,
  getLoginInfo,
  removeLoginInfo,
  setLoginInfo,
} from "./util";
import { Message, Loading } from "element-ui";
import router from "@/router/index";
import { throttle } from "./rateLimiters";
const instance = axios.create({
  baseURL: requestPath,
  timeout: 0,
});

// 使用节流函数包装成功消息的显示
const showSuccessMessage = throttle((message) => {
  Message.success({
    message,
    duration: 800,
  });
}, 2000);

// 使用节流函数包装成功消息的显示
const showSuccessError = throttle((message) => {
  Message.error({
    message,
    duration: 800,
  });
}, 2000);

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    const loginInfo = getLoginInfo();
    if (loginInfo) {
      config.headers[loginInfo.token.tokenName] = loginInfo.token.tokenValue;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    
    // 成功返回结果
    // 二进制数据则直接返回
    if (
      response.request.responseType === "blob" ||
      response.request.responseType === "arraybuffer"
    ) {
      return response;
    }
    // 云币扣减结果
    if (response.headers['x-cloudcoin-balance'] != null) {
      const loginInfo = getLoginInfo();
      loginInfo.cloudCoinBalance = response.data.cloudCoin;
      setLoginInfo(loginInfo);
    }
    //  会员更新结果
    if (response.data.code == 7703) {
      const loginInfo = getLoginInfo();
      loginInfo.memberName = "初级会员";
      setLoginInfo(loginInfo);
    }
    // 登录失效
    if (response.data.code == 205) {
      removeLoginInfo();
      showSuccessError(response.data.message);
      router.push({ name: "login" });
      return Promise.reject(response.data.message);
    }
    // 请求成功
    if (response.data.code == 9963) {
      return response.data.data;
    }
    // 请求成功
    if (response.data.code == 200) {
      showSuccessMessage("成功");
      return response.data.data;
    } else {
      // 错误提示
      showSuccessError(response.data.message);
      return Promise.reject(response.data.message);
    }
  },
  (error) => {
    console.log("err", error);
    let { message } = error;
    if (error.message == "Network Error") {
      message = "网络错误";
    } else if (error.message == "timeout of 0ms exceeded") {
      message = "请求超时";
    }
    showSuccessError(message);
    return Promise.reject("错误");
  }
);

function blobValidate(data) {
  return data instanceof Blob;
}

// 解析 Content-Disposition 头中的文件名
function parseFilenameFromContentDisposition(contentDisposition) {
  console.log(contentDisposition);

  const match = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
    contentDisposition
  );
  if (match != null && match[1]) {
    // 解码文件名
    return decodeURIComponent(match[1].replace(/['"]/g, ""));
  }
  return "数据.xlsx"; // 默认文件名
}

export const download = (url, params) => {
  var downloadLoadingInstance = Loading.service({
    text: "正在下载数据，请稍候",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });

  return instance({
    method: "post",
    url: url,
    data: params,
    responseType: "blob",
  })
    .then((response) => {
      if (blobValidate(response.data)) {
        const filename = parseFilenameFromContentDisposition(
          response.headers["content-disposition"]
        );
        const blob = new Blob([response.data]);
        const urlCreator = window.URL || window.webkitURL;
        const downloadUrl = urlCreator.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        urlCreator.revokeObjectURL(downloadUrl);
      }
      downloadLoadingInstance.close();
    })
    .catch((error) => {
      Message.error("下载文件出现错误，请联系管理员！");
      downloadLoadingInstance.close();
    });
};

export const downloadAly = (url) => {
  var downloadLoadingInstance = Loading.service({
    text: "正在下载数据，请稍候",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });

  return instance({
    method: "get",
    url: url,
    responseType: "blob",
  })
    .then((response) => {
      if (blobValidate(response.data)) {
        const filename = parseFilenameFromContentDisposition(
          response.headers["content-disposition"]
        );
        const blob = new Blob([response.data]);
        const urlCreator = window.URL || window.webkitURL;
        const downloadUrl = urlCreator.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        urlCreator.revokeObjectURL(downloadUrl);
      }
      downloadLoadingInstance.close();
    })
    .catch((error) => {
      Message.error("下载文件出现错误，请联系管理员！");
      downloadLoadingInstance.close();
    });
};

export const downloadAnu = (url, params, filename) => {
  // 创建加载指示器
  var downloadLoadingInstance = Loading.service({
    text: "正在下载数据，请稍候",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });

  // 返回一个新的 Promise
  return new Promise((resolve, reject) => {
    instance({
      method: "post",
      url: url,
      data: params,
      responseType: "blob",
    })
      .then((response) => {
        if (blobValidate(response.data)) {
          const blob = new Blob([response.data]);
          const urlCreator = window.URL || window.webkitURL;
          const downloadUrl = urlCreator.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          urlCreator.revokeObjectURL(downloadUrl);

          // 关闭加载指示器并解析 Promise
          downloadLoadingInstance.close();
          resolve({ success: true, message: "文件下载成功", response });
        } else {
          // 如果验证失败，关闭加载指示器并拒绝 Promise
          downloadLoadingInstance.close();
          reject({ success: false, message: "下载的数据无效" });
        }
      })
      .catch((error) => {
        // 发生错误时，显示消息、关闭加载指示器并拒绝 Promise
        Message.error("下载文件出现错误，请联系管理员！");
        downloadLoadingInstance.close();
        reject({ success: false, message: "下载文件出现错误", error });
      });
  });
};

export default instance;
